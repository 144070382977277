import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type SupplierForm = {
	name: string;
};

const SupplierAddFormSchema = yup.object().shape({
	name: yup.string().required(i18n.t("This field is required")),
});

export default function SupplierAdd() {
	const queryClient = useQueryClient();

	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SupplierForm>({
		resolver: yupResolver(SupplierAddFormSchema),
	});
	const onSubmit = handleSubmit(async (data: SupplierForm) => {
		setIsSending(true);

		await api.post("/suppliers", data).then(() => {
			queryClient.invalidateQueries(["supplier", "list"]);
			navigate("/supplier");
		});

		setIsSending(false);
	});

	return (
		<>
			<PageTitle pretitle={t("Add")} title={t("Supplier")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<form className="card" onSubmit={onSubmit}>
								<div className="card-header">
									<h4 className="card-title">{t("Form")}</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-xl-12">
											<div className="mb-3">
												<label className="form-label">
													{t("Name")}
												</label>
												<input
													type="text"
													className={`form-control ${
														errors.name &&
														"is-invalid is-invalid-lite"
													}`}
													{...register("name")}
												/>
												{errors.name && (
													<div className="invalid-feedback">
														{errors.name.message}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer text-end">
									<div className="d-flex">
										<button
											type="submit"
											className={`btn btn-primary ms-auto ${
												isSending &&
												"disabled btn-loading"
											}`}
										>
											{t("SAVE")}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
