import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type TableListProps = {
	headers: { header: string; field: string }[];
	data:
		| {
				values: { field: string; value: string }[];
		  }[]
		| undefined;
	actions?: {
		field: string;
		value: string;
		link?: string;
		onClick?: (id: string) => void | ((id: number) => void)
		show?: (id: string) => void | ((id: number) => void)
	}[];
};

export default function TableList({ headers, data, actions }: TableListProps) {
	const { t } = useTranslation();

	return (
		<div className="table-responsive">
			<table className="table table-vcenter table-mobile-md card-table table-striped">
				<thead>
					<tr>
						{headers.map((header, index) => (
							<th key={index} className={`${index === 0 ? "w-1" : '' }`}>{header.header}</th>
						))}
						{actions && <th className="w-1" />}
					</tr>
				</thead>
				<tbody>
					{data?.map((info, index) => {
						return (
							<tr key={index}>
								{headers.map((field, index) => (
									<td key={index} data-label={field.field}>
										{
											info.values.find(
												(value) =>
													value.field === field.field
											)?.value
										}
									</td>
								))}

								{actions && (
									<td>
										<div className="btn-list flex-nowrap">
											<div className="dropdown">
												<button
													className="btn dropdown-toggle align-text-top"
													data-bs-toggle="dropdown"
												>
													{t("Actions")}
												</button>
												<div className="dropdown-menu dropdown-menu-end">
													{actions.map((action, index) =>
														action.link ? (
															<Link key={index}
																to={`${
																	action.link
																}/${
																	info.values.find(
																		(
																			value
																		) =>
																			value.field ===
																			action.field
																	)?.value
																}`}
																className="dropdown-item"
															>
																{action.value}
															</Link>
														) : (
															<button key={index}
																className="dropdown-item"
																onClick={() => {
																	const parameter =
																		info.values.find(
																			(
																				value
																			) =>
																				value.field ===
																				action.field
																		)?.value;
																	if (
																		action.onClick &&
																		parameter
																	)
																		action.onClick(
																			parameter
																		);
																}}
															>
																{action.value}
															</button>
														)
													)}
												</div>
											</div>
										</div>
									</td>
								)}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
