import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";

import { Plus as IconTablerPlus } from "tabler-icons-react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LOW_PRIORITY } from "../../../services/ConfigService";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../components/List/SearchBar";
import { useState } from "react";
import Pagination from "../../../components/List/Pagination";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ItemsPerPage from "../../../components/List/ItemsPerPage";
import ShowingEntries from "../../../components/List/ShowingEntries";
import useItemsPerPage from "../../../hooks/useItemsPerPage";
import useSearchBar from "../../../hooks/useSearchBar";
import useSelectPage from "../../../hooks/useSelectPage";
import ListModal from "../../../components/Modal/ListModal";
import TableList from "../../../components/List/TableList";
import { formatDate } from "../../../services/formatters/dateFormat";

type InvoiceType = {
	members: {
		'@id': string;
		id: string;
		number: string;
		supplier: {
			'@id': string;
			id: string;
			name: string;
		};
		acquiredAt: Date;
		createdAt: Date;
		updatedAt: Date;
		devices: {
			'@id': string;
			id: string;
			deviceModel: {
				'@id': string;
				id: string;
				name: string;
			};
			patrimonyTag: {
				'@id': string;
				id: string;
				number: string;
			};
			operationalSystem: {
				'@id': string;
				id: string;
				name: string;
			};
			invoice: {
				'@id': string;
				id: string;
				number: string;
			};
			serialNumber: string;
			description: string;
			createdAt: Date;
			updatedAt: Date;
		}[];
	}[];
	view: {
		total: number;
	};
};

async function fetchInvoices(searchParams: string) {
	const response = await api
		.get(`/invoices?${searchParams}`)
		.then((response) => {
			return response;
		});

	const result: InvoiceType = {
		members: response.data["hydra:member"],
		view: {
			total: response.data["hydra:totalItems"],
		},
	};
	return result;
}

async function deleteInvoice(id: string) {
	await api.delete(`/invoices/${id}`).then((response) => {
		console.log(`ID ${id} Deletado com sucesso`);
	});
}

export function useQueryInvoiceList(searchParams?: URLSearchParams) {
	return useQuery<InvoiceType>(
		["invoice", "list", `${searchParams ? searchParams.toString() : ""}`],
		async () => {
			return fetchInvoices(searchParams ? searchParams.toString() : "");
		},
		{
			staleTime: LOW_PRIORITY,
		}
	);
}

export default function InvoiceList() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState("");
	const [isListModalOpen, setIsListModalOpen] = useState(false);
	const { handleSelectPage, page, setPage } = useSelectPage({
		changeQueryParams,
	});
	const { handleSearchBar, searchText } = useSearchBar({
		setPage,
		changeQueryParams,
	});
	const { handleItemsPerPage, itemsPerPage } = useItemsPerPage({
		setPage,
		changeQueryParams,
	});
	const { data, isLoading } = useQueryInvoiceList(searchParams);
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	function changeQueryParams({
		search = searchText,
		actualPage = String(page),
		items = String(itemsPerPage),
	}) {
		setSearchParams({
			number: search,
			page: actualPage,
			itemsPerPage: items,
		});
	}

	function handleDelete(id: string) {
		setSelectedId(id);
		setIsDeleteModalOpen(true);
	}

	function handleListDevices(id: string) {
		setSelectedId(id);
		setIsListModalOpen(true);
	}

	async function onDeleteConfirm(id: string) {
		await deleteInvoice(id);
		queryClient.invalidateQueries(["invoice", "list"]);
		queryClient.removeQueries(["invoice", id]);
	}

	return (
		<>
			<PageTitle pretitle={t("List")} title={t("Invoice")}>
				<Link
					to={"/supplier/invoice/add"}
					className="btn btn-primary d-none d-sm-inline-block"
				>
					<IconTablerPlus />
					{t("Add")}
				</Link>
				<Link
					to={"/supplier/invoice/add"}
					className="btn btn-primary d-sm-none btn-icon"
				>
					<IconTablerPlus />
				</Link>
			</PageTitle>
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">
								<div className="card-body border-bottom py-2">
									<div className="d-flex align-items-center">
										<ItemsPerPage
											onSelect={handleItemsPerPage}
										/>
										<div className="ms-auto text-muted">
											<SearchBar
												searchText={searchText}
												setSearchText={handleSearchBar}
											/>
										</div>
									</div>
								</div>
								{!isLoading ? (
									<>
										<TableList
											headers={[
												{field: "createdAt", header: t("Created At")},
												{field: "number", header: t("Number")},
												{field: "supplier", header: t("Supplier")},
												{field: "itens", header: t("Itens")},
												{field: "acquiredAt", header: t("Acquired at")},
											]}
											data={data?.members.flatMap(
												(info) => {
													return {
														values: [
															{field: "id", value: info.id},
															{field: "createdAt", value: formatDate(info.createdAt, 'dd/MM/yyyy H:mm')},
															{field: "number", value: info.number},
															{field: "supplier", value: info.supplier.name},
															{field: "itens", value: info.devices.length.toString()},
															{field: "acquiredAt",value: formatDate(info.acquiredAt, 'dd/MM/yyyy H:mm')},
														],
													};
												}
											)}
											actions={[
												{value: t('Edit'), field:'id', link: '/supplier/invoice/edit'},
												{value: t('List devices'), field:'id', onClick: handleListDevices},
												{value: t('Delete'), field:'id', onClick: handleDelete},
											]}
										/>
										<div className="card-footer d-flex align-items-center">
											{data?.members &&
												data.members.length > 0 && (
													<>
														<ShowingEntries
															firstIn={page * itemsPerPage - itemsPerPage + 1}
															lastIn={data.members.length}
															total={data.view.total}
														/>
														<Pagination
															totalPages={Math.ceil(data.view.total / itemsPerPage)}
															actualPage={page}
															onSelectPage={handleSelectPage}
														/>
													</>
												)}
										</div>
									</>
								) : (
									<div className="d-flex justify-content-center p-3">
										<h1>
											{t("Loading")}
											<span className="animated-dots"></span>
										</h1>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				setIsOpen={setIsDeleteModalOpen}
				id={selectedId}
				onConfirm={onDeleteConfirm}
				isOpen={isDeleteModalOpen}
			/>
			<ListModal
				title={t("Devices in this invoice")}
				noDataMessage={t("No data to display")}
				headers={[
					{ field: "serialNumber", header: t("Serial Number") },
					{ field: "deviceModel", header: t("Device Model") },
					{ field: "operationalSystem", header: t("Operational System") },
					{ field: "patrimonyTag", header: t("Patrimony Tag") },
				]}
				data={data?.members
					.find((invoice) => invoice.id === selectedId)
					?.devices.flatMap((device) => {
						return {
							values: [
								{field: "serialNumber",value: device?.serialNumber},
								{field: "deviceModel", value: device.deviceModel?.name},
								{field: "operationalSystem", value: device.operationalSystem?.name},
								{field: "patrimonyTag",value: device.patrimonyTag?.number},
							],
						};
					})}
				isOpen={isListModalOpen}
				setIsOpen={setIsListModalOpen}
			/>
		</>
	);
}
