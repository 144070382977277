import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuerySupplierList } from "../Supplier/SupplierList";

type InvoiceForm = {
	number: string;
	supplier: string;
	acquiredAt: Date;
};

const InvoiceAddFormSchema = yup.object().shape({
	number: yup.string().required(i18n.t("This field is required")),
	supplier: yup.string().required(i18n.t("This field is required")),
	acquiredAt: yup
		.date()
		.typeError(i18n.t("Select a valid date"))
		.max(new Date(), i18n.t("Select a valid date"))
		.required(i18n.t("This field is required")),
});

export default function InvoiceAdd() {
	const queryClient = useQueryClient();
	const { data: supplierData, isLoading: isLoadingSuppliers } =
		useQuerySupplierList(undefined);

	function isLoadingDependency() {
		return isLoadingSuppliers;
	}

	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<InvoiceForm>({
		resolver: yupResolver(InvoiceAddFormSchema),
	});
	const onSubmit = handleSubmit(async (data: InvoiceForm) => {
		setIsSending(true);

		await api.post("/invoices", data).then(() => {
			queryClient.invalidateQueries(["invoice", "list"]);
			navigate("/supplier/invoice");
		});

		setIsSending(false);
	});

	return (
		<>
			<PageTitle pretitle={t("Add")} title={t("Invoice")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoadingDependency() ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-12">
												<div className="col-xl-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Supplier")}
														</div>
														<select
															className={`form-select ${
																errors.supplier &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"supplier",
																{ value: "" }
															)}
														>
															<option value="" />

															{supplierData?.members.map(
																(supplier) => {
																	return (
																		<option
																			key={
																				supplier.id
																			}
																			value={supplier["@id"]}
																		>{`${supplier.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.supplier && (
															<div className="invalid-feedback">
																{
																	errors
																		.supplier
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Number")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.number &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("number")}
													/>
													{errors.number && (
														<div className="invalid-feedback">
															{
																errors.number
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Acquired at")}
													</label>
													<input
														type="datetime-local"
														className={`form-control ${
															errors.acquiredAt &&
															"is-invalid is-invalid-lite"
														}`}
														{...register(
															"acquiredAt"
														)}
													/>
													{errors.acquiredAt && (
														<div className="invalid-feedback">
															{
																errors
																	.acquiredAt
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
