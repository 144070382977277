import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { AuthContext } from "../contexts/AuthContext";

import LogoFarolSign from "../assets/images/icone_logo_farolsign.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import i18n from "../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type LoginForm = {
    username: string;
    password: string;
}

const LoginFormSchema = yup.object().shape({
	username: yup.string().required(i18n.t("This field is required")),
	password: yup.string().required(i18n.t("This field is required")),
})

export default function Login() {
    const { signIn } = useContext(AuthContext);
	const navigate = useNavigate();
    const [isLogging, setIsLogging] = useState(false);
	const [isLoggedFail, setIsLoggedFail] = useState(false);

	const { t } = useTranslation();

    const { register, handleSubmit, reset, formState: { errors } } = useForm<LoginForm>({
		resolver: yupResolver(LoginFormSchema)
	});
    const onSubmit = handleSubmit(async (data: LoginForm) => {
        setIsLogging(true);
		setIsLoggedFail(false);

		const result = await signIn(data);
		if (result) {
			navigate('/');
		}

		setIsLogging(false);
		setIsLoggedFail(true);
		reset();
    });

	return (
		<div className="page page-center">
			<div className="container-tight py-4">
				<div className="text-center mb-4">
					<a href="." className="navbar-brand">
						<img src={LogoFarolSign} height={60} alt="" />
					</a>
				</div>

				<form
					className="card card-md"
					autoComplete="off"
                    onSubmit={onSubmit}
				>
					<div className="card-body">
						<h2 className="card-title text-center mb-4">{t("Login to your account")}</h2>
						<div className="mb-3">
							<label className="form-label">{t("Username")}</label>
							<input
								type="text"
								className={`form-control ${errors.username && 'is-invalid is-invalid-lite'}`}
								autoComplete="off"
                                {...register("username")}
							/>
                            {errors.username && <div className="invalid-feedback">{errors.username.message}</div>}
						</div>

						<div className="mb-2">
                        <label className="form-label">{t("Password")}</label>
							<input
								type="password"
								className={`form-control ${errors.password && 'is-invalid is-invalid-lite'}`}
								autoComplete="off"
                                {...register("password")}
							/>
                            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
						</div>

						{isLoggedFail && (
							<div className="alert alert-warning alert-dismissible" role="alert">
								<h4 className="alert-title">{t("Sorry!")}</h4>
								<div className="text-muted">{t("Login failed, check credentials.")}</div>
							</div>
						)}

						<div className="form-footer">
							<button
                                type="submit"
                                className={`btn btn-primary w-100 ${isLogging && 'disabled btn-loading'}`}
								disabled= {(errors.password || errors.password )? true: false}
                            >
                                {t("Sign in")}
                            </button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
