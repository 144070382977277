import { useState } from "react";

type UseItemPerPageProps = {
	setPage: (page: number) => void;
	changeQueryParams({ search, actualPage, items }: {
		search?: string | undefined;
		actualPage?: string | undefined;
		items?: string | undefined;
	}): void
}

export default function useSearchBar({setPage, changeQueryParams}: UseItemPerPageProps) {
	const [searchText, setSeachText] = useState("")

	function handleSearchBar(text: string) {
		setPage(1);
		setSeachText(text);
		changeQueryParams({search: String(text), actualPage: String(1)})
	}

	return {
		searchText,
		setSeachText,
		handleSearchBar
	}
}
