import { useState } from "react";
import { Search as SearchIcon } from "tabler-icons-react";
import i18n from "../../services/i18n/i18n";

type SearchbarProps = {
	searchText: string;
	setSearchText: (text: string) => void;
	placeholder?: string;
};

export default function SearchBar({
	placeholder = i18n.t("Search..."),
	searchText,
	setSearchText,
}: SearchbarProps) {
	const [searchBarComponentText, setSearchBarComponentText] =
		useState(searchText);
	return (
		<div>
			<div className="input-icon">
				<span className="input-icon-addon">
					<SearchIcon />
				</span>
				<input
					type="text"
					value={searchBarComponentText}
					className="form-control form-control"
					placeholder={placeholder}
					onChange={(e) => setSearchBarComponentText(e.target.value)}
					onKeyDown={(e) => {
						(e.key === "Enter" && e.currentTarget.value !== searchText) &&
							setSearchText(searchBarComponentText);
					}}
					onBlur={(e) => {
						e.preventDefault();
						(e.currentTarget.value !== searchText) &&
						setSearchText(searchBarComponentText)}}
				/>
			</div>
		</div>
	);
}
