import { destroyCookie, parseCookies, setCookie } from "nookies";
import { createContext, ReactNode, useEffect, useState } from "react";

import { api } from "../services/api";
import { useNavigate } from "react-router-dom";

type User = {
	name: string;
	username: string;
	roles: string[];
}

type SignInCredentials = {
	username: string;
	password: string;
}

type AuthContextData = {
	signIn(credentials: SignInCredentials): Promise<boolean>;
	signOut(): void;
	user?: User;
	isAuthenticated: boolean;
	isLoading: boolean;
}

type AuthProviderProps = {
	children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
	const navigate = useNavigate();
	const [user, setUser] = useState<User>();
	const [isLoading, setIsLoading] = useState(true);
	const isAuthenticated = !!user;

	useEffect(() => {
		const { 'fs.token': token } = parseCookies();

		if (token) {
			api.get('/me', {
				headers: {
					'Accept': 'application/json',
				}
			}).then(response => {
				const { user } = response.data;
				setUser(user);
			}).catch(error => {
				signOut();
			}).finally(() => {
				setIsLoading(false);
			});
		} else {
			setIsLoading(false);
		}
	}, []);

	async function signIn({ username, password }: SignInCredentials) {
		try {
			const response = await api.post('login', {
                username,
                password,
            });

			const { user, token, refresh_token } = response.data;

			setUser(user);

            setCookie(undefined, 'fs.token', token, {
                maxAge: 3600 * 24 * 30, // 30 dias
                path: '/'
            });

            setCookie(undefined, 'fs.refresh_token', refresh_token, {
                maxAge: 3600 * 24 * 30, // 30 dias
                path: '/'
            });

            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			return true;
		} catch (error) {

        }

		return false;
	}

	function signOut() {
		destroyCookie(undefined, 'fs.token');
		destroyCookie(undefined, 'fs.refresh_token');

		navigate('/login');
	}

	return (
		<AuthContext.Provider value={{ signIn, signOut, user, isAuthenticated, isLoading }}>
			{children}
		</AuthContext.Provider>
	);
};
