import { title } from "process";
import { ReactElement } from "react";
import {
	Link,
	To,
	useLocation,
	useMatch,
	useResolvedPath,
} from "react-router-dom";

import { Home as IconTablerHome, Box as IconTablerBox, Users as IconTablerUsers} from "tabler-icons-react";
import { DeviceAnalytics as IconTablerDeviceAnalytics } from "tabler-icons-react";
import i18n from "../../services/i18n/i18n";

const { t } = i18n;

type MenuItemType = {
	title: string;
	path: To;
	icon?: ReactElement;
	subitems?: SubMenuItemType[];
};

type SubMenuItemType = {
	title: string;
	path: string;
};

type MenuItemProp = {
	item: MenuItemType;
};

type DropdownProp = {
	subitems: SubMenuItemType[];
};

const menuItems: MenuItemType[] = [
	{
		title: t("Home"),
		path: "/",
		icon: <IconTablerHome />,
	},
	{
		title: t("Users"),
		path: "/user",
		icon: <IconTablerUsers />,
	},
	{
		title: t("Suppliers"),
		path: "",
		icon: <IconTablerBox />,
		subitems: [
			{
				title:  t("Suppliers"),
				path: '/supplier'
			},
			{
				title:  t("Invoices"),
				path: '/supplier/invoice'
			},
		]
	},
	{
		title: t("Device"),
		path: "",
		icon: <IconTablerDeviceAnalytics />,
		subitems: [
			{
				title:  t("Device"),
				path: '/device'
			},
			{
				title: t("Model"),
				path: '/device/device-model'
			},
			{
				title: t("Type"),
				path: '/device/device-type'
			},

			{
				title: t("Operational System"),
				path: '/device/operational-system'
			},

			{
				title: t("Patrimony Tag"),
				path: "/device/patrimony-tag"
			},

			{
				title: t("Manufacturer"),
				path: "/device/manufacturer",
			},
		],
	},
];

const Navbar = () => {
	return (
		<ul className="navbar-nav">
			{menuItems.map((menu, index) => {
				return <MenuItem item={menu} key={index} />;
			})}
		</ul>
	);
};

const MenuItem = ({ item }: MenuItemProp) => {
	let resolved = useResolvedPath(item.path);
	let match =
		useMatch({ path: resolved.pathname, end: true }) && item.path !== "";

	const location = useLocation();
	if (item.subitems) {
		const findPath = item.subitems.filter((item) => {
			return location.pathname === item.path;
		});

		if (findPath.length > 0) {
			match = true;
		}
	}

	return (
		<li
			className={`nav-item${match ? " active" : ""}${
				item.subitems ? " dropdown" : ""
			}`}
		>
			{item.subitems ? (
				<>
					<Link
						to={`${item.path}`}
						className="nav-link dropdown-toggle"
						data-bs-toggle="dropdown"
						data-bs-auto-close="outside"
						role="button"
						aria-expanded="false"
					>
						{item.icon && (
							<span className="nav-link-icon d-md-none d-lg-inline-block">
								{item.icon}
							</span>
						)}
						{item.title}
					</Link>
					<Dropdown subitems={item.subitems}/>
				</>
			) : (
				<Link className="nav-link" to={`${item.path}`}>
					{item.icon && (
						<span className="nav-link-icon d-md-none d-lg-inline-block">
							{item.icon}
						</span>
					)}
					{item.title}
				</Link>
			)}
		</li>
	);
};

const Dropdown = ({ subitems }: DropdownProp) => {
	const location = useLocation();

	return (
		<div className={'dropdown-menu'}>
			<div className="dropdown-menu-columns">
				<div className="dropdown-menu-column">
					{subitems.map((subitem, index) => (
						<Link
							key={index}
							to={subitem.path}
							className={`dropdown-item${
								location.pathname === subitem.path
									? " active"
									: ""
							}`}
						>
							{subitem.title}
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};

export default Navbar;
