import { ChevronLeft, ChevronRight } from "tabler-icons-react";

type PaginationProps = {
	actualPage: number;
	totalPages: number;
	onSelectPage: (page: number) => void;
};

export default function Pagination({
	actualPage,
	totalPages,
	onSelectPage
}: PaginationProps) {
	let pagination: number[] = [];

	if (totalPages <= 5) {
		for (let index = 1; index <= totalPages; index++) {
			pagination.push(index);
		}
	} else {
		const firstPageIcon = actualPage - 2 <= 1 ? 1 : actualPage - 2;

		const endPageIcon = actualPage + 2 >= totalPages ? totalPages : actualPage + 2;

		if(firstPageIcon === 1) {
			for (let index = 1; index <= 5; index++) {
				pagination.push(index);
			}
		} else if(endPageIcon === totalPages){
			for (let index = endPageIcon - 4; index <= endPageIcon; index++) {
				pagination.push(index);
			}
		} else {
			for (let index = firstPageIcon; index <= endPageIcon; index++) {
				pagination.push(index);
			}
		}
	}

	return (
		<ul className={`pagination m-0 ms-auto`}>
			<li className={`page-item ${((actualPage===1)) ? "disabled": ''}`}>
				<button className="page-link" onClick={() => onSelectPage(1)}>
					<ChevronLeft />
				</button>
			</li>
			<li className={`page-item ${(actualPage===1) && "disabled"}`}>
				<button className="page-link" onClick={() => onSelectPage(actualPage-1)}>
					prev
				</button>
			</li>

			{pagination.map((pagination) => {
				return (
					<li className={`page-item ${pagination === actualPage? 'active' : ''}`} key={pagination}>
						<button className="page-link" onClick={() => onSelectPage(pagination)}>
							{pagination}
						</button>
					</li>
				);
			})}

			<li className={`page-item ${(actualPage===totalPages) && "disabled"}`}>
				<button className="page-link" onClick={() => onSelectPage(actualPage+1)}>
					next
				</button>
			</li>
			<li className={`page-item ${((actualPage===totalPages)) ? "disabled": ''}`}>
				<button className="page-link" onClick={() => onSelectPage(totalPages)}>
					<ChevronRight />
				</button>
			</li>
		</ul>
	);
}
