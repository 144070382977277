import { ReactNode } from "react";

interface PageTitleProps {
	title?: string;
	pretitle?: string;
	children?: ReactNode;
}

export function PageTitle({ title, pretitle, children }: PageTitleProps) {
	return (
		<div className="container-xl">
			<div className="page-header d-print-none">
				<div className="row g-2 align-items-center">
					<div className="col">
						{pretitle && (
							<div className="page-pretitle">{pretitle}</div>
						)}
						<h2 className="page-title">{title}</h2>
					</div>
					<div className="col-12 col-md-auto ms-auto d-print-none">
						<div className="btn-list">
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
