export function Footer() {
	const dt = new Date();
	return (
		<footer className="footer footer-transparent d-print-none">
			<div className="container-xl">
				<div className="row text-center align-items-center flex-row-reverse">
					<div className="col-12 col-lg-auto mt-3 mt-lg-0">
						<ul className="list-inline list-inline-dots mb-0">
							<li className="list-inline-item">
								Copyright © { dt.getFullYear() } <a href="https://www.farolsign.com.br" className="link-secondary">Farol Sign</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}
