import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";
import { LOW_PRIORITY } from "../../../services/ConfigService";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryManufacturerList } from "../Manufacturer/ManufacturerList";
import { useQueryDeviceTypeList } from "../Type/TypeList";

type DeviceModelForm = {
	name: string;
	description: String;
	manufacture: string;
	deviceType: string;
};

type DeviceModelType = {
	'@id': string;
	id: string;
	name: string;
	description: string;
	manufacture: {
		'@id': string;
		id: string;
		name: string;
	};
	deviceType: {
		'@id': string;
		id: string;
		name: string;
	};
	createdAt: Date;
	updatedAt: Date;
};

const DeviceModelEditFormSchema = yup.object().shape({
	name: yup.string().required(i18n.t("This field is required")),
	description: yup.string().required(i18n.t("This field is required")),
});

export default function DeviceModelEdit() {
	const { data: manufaturersData, isLoading: isLoadingManufacturer } =
		useQueryManufacturerList();

	const { data: deviceTypeData, isLoading: isLoadingdeviceType } =
		useQueryDeviceTypeList();
	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);
	const { id } = useParams();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data, isLoading } = useQuery<DeviceModelType>(
		[`device_model`, id],
		async () => {
			const response = await api
				.get(`/device_models/${id}`)
				.then((response) => {
					return response;
				});

			return response.data as DeviceModelType;
		},
		{
			staleTime: Number(LOW_PRIORITY),
		}
	);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<DeviceModelForm>({
		resolver: yupResolver(DeviceModelEditFormSchema),
	});
	const onSubmit = handleSubmit(async (formData: DeviceModelForm) => {
		setIsSending(true);
		data && api.put(data["@id"], formData).then(() => {
			queryClient.invalidateQueries(["device_model", "list"]);
			queryClient.invalidateQueries([`device_model`, id]);
			setIsSending(false);
			navigate(`/device/device-model`);
		});
	});

	const isLoadingDependency = isLoadingdeviceType || isLoadingManufacturer || isLoading;

	useEffect(() => {
		data && reset({
			name: data.name,
			description: data.description,
			manufacture: data.manufacture["@id"],
			deviceType: data.deviceType["@id"],
		})
	},[isLoadingDependency])

	return (
		<>
			<PageTitle pretitle={t("Edit")} title={t("Device Model")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoadingDependency ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12">
												<div className="col-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Manufacturer")}
														</div>
														<select
															className={`form-select ${
																errors.manufacture &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"manufacture"
															)}
														>
															<option value="" />

															{manufaturersData?.members.map(
																(
																	manufacturer
																) => {
																	return (
																		<option
																			key={
																				manufacturer.id
																			}
																			value={manufacturer["@id"]}
																		>{`${manufacturer.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.manufacture && (
															<div className="invalid-feedback">
																{
																	errors
																		.manufacture
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="col-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Device Type")}
														</div>
														<select
															className={`form-select ${
																errors.deviceType &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"deviceType"
															)}
														>
															<option value="" />
															{deviceTypeData?.members.map(
																(
																	deviceType
																) => {
																	return (
																		<option
																			value={deviceType["@id"]}
																			key={
																				deviceType.id
																			}
																		>{`${deviceType.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.deviceType && (
															<div className="invalid-feedback">
																{
																	errors
																		.deviceType
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Name")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.name &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("name")}
													/>
													{errors.name && (
														<div className="invalid-feedback">
															{
																errors.name
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Description")}
													</label>
													<textarea
														className={`form-control ${
															errors.description &&
															"is-invalid is-invalid-lite"
														}`}
														data-bs-toggle="autosize"
														placeholder={t(
															"Type something…"
														)}
														{...register(
															"description"
														)}
													></textarea>

													{errors.description && (
														<div className="invalid-feedback">
															{
																errors
																	.description
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
