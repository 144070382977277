import { useTranslation } from "react-i18next";

type ShowingEntriesProps = {
	firstIn: number,
	lastIn: number,
	total: number,
}

export default function ShowingEntries({firstIn, lastIn, total}:ShowingEntriesProps) {
	const { t } = useTranslation();
	return (
		<p className="m-0 text-muted">
			{t('Showing')} <span>{firstIn}</span> {t('to')} <span>{lastIn}</span> {t('of')} <span>{total}</span> {t('entries')}
		</p>
	);
}
