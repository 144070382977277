import { createContext, ReactNode, useEffect } from "react";
import usePersistedState from "../utils/usePersistedState";

type ThemeContextData = {
	toggleTheme(): void;
	theme: string;
}

type ThemeProviderProps = {
	children: ReactNode;
}

export const ThemeContext = createContext({} as ThemeContextData);

export function ThemeProvider({ children }: ThemeProviderProps) {
	const isDarkTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
	const [theme, setTheme] = usePersistedState('theme', isDarkTheme() ? 'dark' : 'light');

	function toggleTheme() {
		setTheme(theme === 'light' ? 'dark' : 'light');
	}

	useEffect(() => {
		document.body.className = `theme-${theme}`;
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ toggleTheme, theme }}>
			{children}
		</ThemeContext.Provider>
	);
}
