import format from "date-fns/format";

type AcceptFormats = "yyyy-MM-ddTHH:mm" | "dd/MM/yyyy H:mm"


export function formatDate(date: Date, dateFormat: AcceptFormats) {

	if (dateFormat === 'dd/MM/yyyy H:mm')
	return 	`${format(
		new Date(
			date
		),
		"dd/MM/yyyy"
	)}\u00A0${format(
		new Date(
			date
		),
		"H:mm"
	)}`

	if (dateFormat === 'yyyy-MM-ddTHH:mm') {
		return date.toString().slice(0, 16)
	}

	return 'undefined type date'
}
