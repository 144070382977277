import format from "date-fns/format";

import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";

import { Plus as IconTablerPlus } from "tabler-icons-react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LOW_PRIORITY } from "../../../services/ConfigService";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../components/List/SearchBar";
import { useState } from "react";
import Pagination from "../../../components/List/Pagination";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ItemsPerPage from "../../../components/List/ItemsPerPage";
import ShowingEntries from "../../../components/List/ShowingEntries";
import useItemsPerPage from "../../../hooks/useItemsPerPage";
import useSearchBar from "../../../hooks/useSearchBar";
import useSelectPage from "../../../hooks/useSelectPage";
import TableList from "../../../components/List/TableList";
import { formatDate } from "../../../services/formatters/dateFormat";

type ManufacturerType = {
	members: {
		"@id": string;
		id: string;
		name: string;
		createdAt: Date;
		updatedAt: Date;
	}[];
	view: {
		total: number;
	};
};

async function fetchManufacturers(searchParams: string) {
	const response = await api
		.get(`/manufacturers${searchParams ? `?${searchParams}` : ""}`)
		.then((response) => {
			return response;
		});

	const result: ManufacturerType = {
		members: response.data["hydra:member"],
		view: {
			total: response.data["hydra:totalItems"],
		},
	};
	return result;
}

async function deleteManufacturer(id: string) {
	await api.delete(`/manufacturers/${id}`).then((response) => {
		console.log(`ID ${id} Deletado com sucesso`);
	});
}

export function useQueryManufacturerList(searchParams?: URLSearchParams) {
	return useQuery<ManufacturerType>(
		[
			"manufacturers",
			"list",
			`${searchParams ? searchParams?.toString() : ""}`,
		],
		async () => {
			return fetchManufacturers(
				searchParams ? searchParams.toString() : ""
			);
		},
		{
			staleTime: LOW_PRIORITY,
		}
	);
}

export default function ManufacturerList() {
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleSelectPage, page, setPage } = useSelectPage({
		changeQueryParams,
	});
	const { handleSearchBar, searchText } = useSearchBar({
		setPage,
		changeQueryParams,
	});
	const { handleItemsPerPage, itemsPerPage } = useItemsPerPage({
		setPage,
		changeQueryParams,
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState("");
	const queryClient = useQueryClient();

	const { t } = useTranslation();

	function changeQueryParams({
		search = searchText,
		actualPage = String(page),
		items = String(itemsPerPage),
	}) {
		setSearchParams({
			name: search,
			description: search,
			page: actualPage,
			itemsPerPage: items,
		});
	}

	function handleDelete(id: string) {
		setIsModalOpen(true);
		setSelectedId(id);
	}

	async function onDeleteConfirm(id: string) {
		await deleteManufacturer(id);
		queryClient.invalidateQueries(["manufacturers", "list"]);
		queryClient.removeQueries(["manufacturers", id]);
	}

	const { data, isLoading } = useQueryManufacturerList(searchParams);

	return (
		<>
			<PageTitle pretitle={t("List")} title={t("Manufacturer")}>
				<Link
					to={"/device/manufacturer/add"}
					className="btn btn-primary d-none d-sm-inline-block"
				>
					<IconTablerPlus />
					{t("Add")}
				</Link>
				<Link
					to={"/device/manufacturer/add"}
					className="btn btn-primary d-sm-none btn-icon"
				>
					<IconTablerPlus />
				</Link>
			</PageTitle>
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">
								<div className="card-body border-bottom py-2">
									<div className="d-flex align-items-center">
										<ItemsPerPage
											onSelect={handleItemsPerPage}
										/>
										<div className="ms-auto text-muted">
											<SearchBar
												searchText={searchText}
												setSearchText={handleSearchBar}
											/>
										</div>
									</div>
								</div>
								{!isLoading ? (
									<>
										<TableList
											headers={[
												{
													field: "createdAt",
													header: t("Created At"),
												},
												{
													field: "name",
													header: t("Name"),
												},
											]}
											data={data?.members.flatMap(
												(info) => {
													return {
														values: [
															{
																field: "id",
																value: info.id,
															},
															{
																field: "createdAt",
																value: formatDate(
																	info.createdAt,
																	"dd/MM/yyyy H:mm"
																),
															},
															{
																field: "name",
																value: info.name,
															},
														],
													};
												}
											)}
											actions={[
												{
													value: t("Edit"),
													field: "id",
													link: "/device/manufacturer/edit",
												},
												{
													value: t("Delete"),
													field: "id",
													onClick: handleDelete,
												},
											]}
										/>
										<div className="card-footer d-flex align-items-center">
											{data?.members &&
												data.members.length > 0 && (
													<>
														<ShowingEntries
															firstIn={
																page *
																	itemsPerPage -
																itemsPerPage +
																1
															}
															lastIn={
																data.members
																	.length
															}
															total={
																data.view.total
															}
														/>
														<Pagination
															totalPages={Math.ceil(
																data.view
																	.total /
																	itemsPerPage
															)}
															actualPage={page}
															onSelectPage={
																handleSelectPage
															}
														/>
													</>
												)}
										</div>
									</>
								) : (
									<div className="d-flex justify-content-center p-3">
										<h1>
											{t("Loading")}
											<span className="animated-dots"></span>
										</h1>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				setIsOpen={setIsModalOpen}
				id={selectedId}
				onConfirm={onDeleteConfirm}
				isOpen={isModalOpen}
			/>
		</>
	);
}
