import { useState } from "react";
import { DEFAULT_ITEM_PER_PAGE } from "../services/ConfigService";

type UseItemPerPageProps = {
	setPage: (page: number) => void;
	changeQueryParams({ search, actualPage, items }: {
		search?: string | undefined;
		actualPage?: string | undefined;
		items?: string | undefined;
	}): void
}

export default function useItemsPerPage({setPage, changeQueryParams}: UseItemPerPageProps) {
	const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEM_PER_PAGE)

	function handleItemsPerPage(items: number) {
		setPage(1);
		setItemsPerPage(items);
		changeQueryParams({actualPage: String(1), items: String(items)})
	}

	return {
		itemsPerPage,
		setItemsPerPage,
		handleItemsPerPage
	}
}
