import { useTranslation } from "react-i18next";
import TableList from "../List/TableList";

type ListModalProps = {
	title: string;
	noDataMessage: string;
	headers: { header: string; field: string }[];
	data: {
		values: { field: string; value: string }[];
	}[] | undefined;
	isOpen: boolean;
	setIsOpen(isOpen: boolean): void;
};

export default function ListModal({
	title,
	noDataMessage,
	headers,
	data,
	isOpen,
	setIsOpen,
}: ListModalProps) {
	const { t } = useTranslation();

	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div
				className="modal d-block show modal-blur fade"
				onClickCapture={(e) => {
					if (e.target === e.currentTarget) {
						setIsOpen(false);
					}
				}}
			>
				<div
					className="modal-dialog modal-dialog-centered modal-xl"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setIsOpen(false)
								}}
							></button>
						</div>
						<div className="modal-body">
							{(data?.length !== undefined && data?.length > 0) ? <TableList headers={headers} data={data}/> : <h5>{noDataMessage}</h5>}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" onClick={() => setIsOpen(false)}>
								{t('Close')}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`modal-backdrop fade ${isOpen ? "show" : "d-none"}`}
			></div>
		</>
	);
}
