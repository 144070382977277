import { useState } from "react";

type UseItemPerPageProps = {
	changeQueryParams({ search, actualPage, items }: {
		search?: string | undefined;
		actualPage?: string | undefined;
		items?: string | undefined;
	}): void
}

export default function useSelectPage({changeQueryParams}: UseItemPerPageProps) {
	const [page, setPage] = useState(1);


	function handleSelectPage(nextPage: number) {
		setPage(nextPage);
		changeQueryParams({actualPage: String(nextPage)})
	}

	return {
		page,
		setPage,
		handleSelectPage
	}
}
