import { PageTitle } from "../components/Layout/PageTitle";

export default function Home() {
	return (
		<>
			<PageTitle
				title="Home"
			/>
			<div className="page-body">
				<div className="container-xl">
				</div>
			</div>
		</>
	);
}
