import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertTriangle as AlertTriangleIcon } from "tabler-icons-react";

type DeleModalProps = {
	question?: string;
	infoMessage?: string;
	cancelMessage?: string;
	confirmMessage?: string;
	id: string;
	isOpen: boolean;
	setIsOpen(isOpen: boolean): void;
	onConfirm(id: string): Promise<void>;
};

export default function DeleteModal({
	cancelMessage = "Cancel",
	infoMessage = "Do you really want to delete? This action is not reversible.",
	confirmMessage = "Delete",
	question = "Are you sure?",
	id,
	isOpen,
	setIsOpen,
	onConfirm,
}: DeleModalProps) {
	const [isSending, setIsSending] = useState(false);
	const { t } = useTranslation();

	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div
				className={`modal ${
					isOpen ? "d-block show" : "d-none"
				} modal-blur fade`}
				aria-modal="true"
				role="dialog"
				tabIndex={-1}
				onClickCapture={(e) => {
					if(e.target === e.currentTarget && isSending === false) {
						setIsOpen(false)
					}
				}}
			>
				<div
					className="modal-dialog modal-sm modal-dialog-centered"
					role="document"
				>
					<div className="modal-content border-top-0 border-danger">
						<button
							type="button"
							className={`btn-close ${isSending && "disabled"}`}
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => setIsOpen(false)}
						></button>
						<div className="modal-status bg-danger"></div>
						<div className="modal-body text-center py-4">
							<AlertTriangleIcon className="mb2 text-danger icon-lg" />
							<h3>{t(question)}</h3>
							<div className="text-muted">{t(infoMessage)}</div>
						</div>
						<div className="modal-footer">
							<div className="w-100">
								<div className="row">
									<div className="col">
										<button
											className={`btn w-100 ${isSending && "disabled"}`}
											data-bs-dismiss="modal"
											onClick={() => setIsOpen(false)}
										>
											{t(cancelMessage)}
										</button>
									</div>
									<div className="col">
										<button
											className={`btn btn-danger w-100 ${isSending && "disabled btn-loading"}`}
											data-bs-dismiss="modal"
											onClick={async () => {
												setIsSending(true);
												await onConfirm(id);
												setIsSending(false);
												setIsOpen(false);
											}}
										>
											{t(confirmMessage)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`modal-backdrop fade ${isOpen ? "show" : "d-none"}`}
			></div>
		</>
	);
}
