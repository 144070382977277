import { useTranslation } from "react-i18next";
import { DEFAULT_ITEM_PER_PAGE, ITEMS_PER_PAGE } from "../../services/ConfigService";

type ItemsPerPageProps = {
	onSelect: (itemsPerPage: number) => void;
};

export default function ItemsPerPage({
	onSelect
}: ItemsPerPageProps) {
	const { t } = useTranslation();

	return (
		<div className="text-muted">
			{t("Show")}
			<div className="mx-2 d-inline-block">
				<select
					className="form-select form-select-sm"
					defaultValue={DEFAULT_ITEM_PER_PAGE}
					onChange={(e) => {
						onSelect(Number(e.target.value));
					}}
				>
					{ITEMS_PER_PAGE.map((items) => {
						return <option value={items} key={items}>{items}</option>
					})}
				</select>
			</div>
			{t("entries")}
		</div>
	);
}
