import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";
import { LOW_PRIORITY } from "../../../services/ConfigService";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuerySupplierList } from "../Supplier/SupplierList";
import { formatDate } from "../../../services/formatters/dateFormat";

type InvoiceForm = {
	number: string;
	supplier: string;
	acquiredAt: string;
};

type InvoiceType = {
	'@id': string;
	id: string;
	number: string;
	supplier: {
		'@id': string;
		id: string;
		name: string;
	};
	acquiredAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

const InvoiceEditFormSchema = yup.object().shape({
	number: yup.string().required(i18n.t("This field is required")),
	supplier: yup.string().required(i18n.t("This field is required")),
	acquiredAt: yup
		.date()
		.typeError(i18n.t("Select a valid date"))
		.max(new Date(), i18n.t("Select a valid date"))
		.required(i18n.t("This field is required")),
});

export default function InvoiceEdit() {
	const { data: supplierData, isLoading: isLoadingSuppliers } =
		useQuerySupplierList(undefined);

	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);
	const { id } = useParams();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data, isLoading } = useQuery<InvoiceType>(
		[`invoice`, id],
		async () => {
			const response = await api
				.get(`/invoices/${id}`)
				.then((response) => {
					return response;
				});

			return response.data as InvoiceType;
		},
		{
			staleTime: Number(LOW_PRIORITY),
		}
	);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<InvoiceForm>({
		resolver: yupResolver(InvoiceEditFormSchema),
	});
	const onSubmit = handleSubmit(async (formData: InvoiceForm) => {
		setIsSending(true);
		data && api.put(data["@id"], formData).then(() => {
			queryClient.invalidateQueries(["invoice", "list"]);
			queryClient.invalidateQueries([`invoice`, id]);
			setIsSending(false);
			navigate(`/supplier/invoice`);
		});
	});

	const isLoadingDependency= isLoadingSuppliers || isLoading;

	useEffect(()=> {
		data && reset({
			number: data?.number ,
			acquiredAt: formatDate(data?.acquiredAt, 'yyyy-MM-ddTHH:mm'),
			supplier: data?.supplier["@id"]
		})
	}, [isLoadingDependency])


	return (
		<>
			<PageTitle pretitle={t("Edit")} title={t("Invoice")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoadingDependency ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12">
												<div className="col-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Supplier")}
														</div>
														<select
															className={`form-select ${
																errors.supplier &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"supplier"
															)}
														>
															<option value="" />

															{supplierData?.members.map(
																(supplier) => {
																	return (
																		<option
																			key={
																				supplier.id
																			}
																			value={supplier["@id"]}
																		>{`${supplier.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.supplier && (
															<div className="invalid-feedback">
																{
																	errors
																		.supplier
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Number")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.number &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("number")}
													/>
													{errors.number && (
														<div className="invalid-feedback">
															{
																errors.number
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Acquired at")}
													</label>
													<input
														type="datetime-local"
														className={`form-control ${
															errors.acquiredAt &&
															"is-invalid is-invalid-lite"
														}`}
														{...register(
															"acquiredAt"
														)}
													/>
													{errors.acquiredAt && (
														<div className="invalid-feedback">
															{
																errors
																	.acquiredAt
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
