import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "../../services/i18n/i18n";
import { PageTitle } from "../../components/Layout/PageTitle";
import { api } from "../../services/api";
import { Eye, EyeOff } from "tabler-icons-react";

type UserForm = {
	name: string;
	email: string;
	plainPassword: string;
	plainPasswordConfirmation: string;
};

const UserAddFormSchema = yup.object().shape({
	name: yup.string().required(i18n.t("This field is required")),
	email: yup.string().email(i18n.t("This field must be of type email")).required(i18n.t("This field is required")),
	plainPassword: yup.string().required(i18n.t("This field is required")),
	plainPasswordConfirmation: yup.string().oneOf([yup.ref('plainPassword'), null], i18n.t("Passwords must match")).required(i18n.t("This field is required")),
});

export default function UserAdd() {
	const queryClient = useQueryClient();
	const [passwordVisible, setPasswordVisible] = useState(false);

	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);

	const { t } = useTranslation();

	const handlePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<UserForm>({
		resolver: yupResolver(UserAddFormSchema),
	});
	const onSubmit = handleSubmit(async (data: UserForm) => {
		setIsSending(true);
		const userData : Omit<UserForm, "plainPasswordConfirmation"> = {
			email: data.email,
			name: data.name,
			plainPassword: data.plainPassword
		}

		await api.post("/users", userData).then(() => {
			queryClient.invalidateQueries(["user", "list"]);
			navigate("/user");
		});

		setIsSending(false);
	});

	return (
		<>
			<PageTitle pretitle={t("Add")} title={t("User")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<form
								className="card"
								autoComplete="off"
								onSubmit={onSubmit}
							>
								<div className="card-header">
									<h4 className="card-title">{t("Form")}</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-xl-12">
											<div className="mb-3">
												<label className="form-label">
													{t("Name")}
												</label>
												<input
													type="text"
													autoComplete="off"
													className={`form-control ${
														errors.name &&
														"is-invalid is-invalid-lite"
													}`}
													{...register("name")}
												/>
												{errors.name && (
													<div className="invalid-feedback">
														{errors.name.message}
													</div>
												)}
											</div>
											<div className="mb-3">
												<label className="form-label">
													{t("Email")}
												</label>
												<input
													type="email"
													autoComplete="off"
													className={`form-control ${
														errors.email &&
														"is-invalid is-invalid-lite"
													}`}
													{...register("email")}
												/>
												{errors.email && (
													<div className="invalid-feedback">
														{errors.email.message}
													</div>
												)}
											</div>
											<div className="mb-3 col-6">
													<label className="form-label">
														{t("Password")}
													</label>
													<div className="d-flex flex-row align-items-center">
														<input
															type={
																passwordVisible
																	? "text"
																	: "password"
															}
															autoComplete="new-password"
															className={`form-control ${
																errors.plainPassword &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"plainPassword"
															)}
														/>
														{!passwordVisible ? (
															<Eye
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														) : (
															<EyeOff
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														)}
													</div>
													{errors.plainPassword && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.plainPassword
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3 col-6">
													<label className="form-label">
														{t(
															"Password confirmation"
														)}
													</label>
													<div className="d-flex flex-row align-items-center">
														<input
															type={
																passwordVisible
																	? "text"
																	: "password"
															}
															autoComplete="new-password"
															className={`form-control ${
																errors.plainPasswordConfirmation &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"plainPasswordConfirmation"
															)}
														/>
														{!passwordVisible ? (
															<Eye
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														) : (
															<EyeOff
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														)}
													</div>
													{errors.plainPasswordConfirmation && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.plainPasswordConfirmation
																	.message
															}
														</div>
													)}
												</div>
										</div>
									</div>
								</div>
								<div className="card-footer text-end">
									<div className="d-flex">
										<button
											type="submit"
											className={`btn btn-primary ms-auto ${
												isSending &&
												"disabled btn-loading"
											}`}
										>
											{t("SAVE")}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
