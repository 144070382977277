import { useTranslation } from 'react-i18next';
import { ArrowLeft as IconTablerArrowLeft } from 'tabler-icons-react';

export default function NoPage() {
	const {t} = useTranslation();
	return (
		<div className="page page-center">
			<div className="container-tight py-4">
				<div className="empty">
					<div className="empty-header">404</div>
					<p className="empty-title">Oops…</p>
					<p className="empty-subtitle text-muted">
					{t("Sorry, the page you are looking for was not found.")}
					</p>
					<div className="empty-action">
						<a href="/" className="btn btn-primary">
							<IconTablerArrowLeft />
							{t('Take me home')}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
