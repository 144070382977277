import axios, { AxiosError } from "axios";
import { parseCookies, setCookie } from "nookies";
import { API_PATH } from "./ConfigService";

let cookies = parseCookies();
let isRefreshing = false;
let failedRequestsQueue: { onSuccess: (token: string) => void; onFailure: (error: AxiosError<unknown, any>) => void; }[] = [];

export const api = axios.create({
    baseURL: API_PATH,
});

api.defaults.headers.common['Authorization'] = `Bearer ${cookies['fs.token']}`;

api.interceptors.response.use(response => {
    return response;
}, (error: AxiosError) => {
    if (error.response?.status === 401) {
        const data = JSON.parse(JSON.stringify(error.response?.data));
        if (data.message === 'Expired JWT Token') {
            cookies = parseCookies();

            const { 'fs.refresh_token': refreshToken } = cookies;
            const originalConfig = error.config;

            if (!isRefreshing) {
                isRefreshing = true;

                api.defaults.headers.common['Authorization'] = '';
                api.post('/refresh_token', {
                    refresh_token: refreshToken,
                }).then((response) => {
                    setCookie(undefined, 'fs.token', response.data.token, {
                        maxAge: 3600 * 24 * 30, // 30 dias
                        path: '/'
                    });

                    setCookie(undefined, 'fs.refresh_token', response.data.refresh_token, {
                        maxAge: 3600 * 24 * 30, // 30 dias
                        path: '/'
                    });

                    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

                    failedRequestsQueue.forEach(request => request.onSuccess(response.data.token));
                    failedRequestsQueue = [];
                }).catch((error) => {
                    failedRequestsQueue.forEach(request => request.onFailure(error));
                    failedRequestsQueue = [];
                })
                .finally(() => {
                    isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
                failedRequestsQueue.push({
                    onSuccess: (token: string) => {
                        const headers = JSON.parse(JSON.stringify(originalConfig.headers));
                        headers.Authorization = `Bearer ${token}`

                        originalConfig.headers = headers;

                        resolve(api(originalConfig));
                    },
                    onFailure: (error: AxiosError) => {
                        reject(error);
                    }
                });
            });
        } else {
			console.log(`api.interceptors.error.status.401`);
        }
    }

    return Promise.reject(error);
});
