import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';

import { Footer } from './Footer';
import { Header } from './Header';
import Navbar from './Navbar';

const Layout = () => {
	const { isLoading } = useContext(AuthContext);

	if (isLoading) {
		return null;
	}

	return (
		<div className="page">
			<Header />
			<div className="navbar-expand-md">
				<div className="collapse navbar-collapse" id="navbar-menu">
					<div className="navbar navbar-light">
						<div className="container-xl">
							<Navbar />
						</div>
					</div>
				</div>
			</div>
			<div className="page-wrapper">
				<Outlet />
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
