import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryDeviceModelList } from "../Model/ModelList";
import { useQueryPatrimonyTagList } from "../PatrimonyTag/PatrimonyTagList";
import { useQueryOperationalSystemList } from "../OperationalSystem/OperationalSystemList";
import { useQueryInvoiceList } from "../../Supplier/Invoice/InvoiceList";

type DeviceForm = {
	serialNumber: string;
	description: string;
	invoice: string;
	deviceModel: string;
	operationalSystem: string;
	patrimonyTag: string;
};

const DeviceAddFormSchema = yup.object().shape({
	serialNumber: yup.string().required(i18n.t("This field is required")),
	description: yup.string().required(i18n.t("This field is required")),
	invoice: yup.string().required(i18n.t("This field is required")),
	deviceModel: yup.string().required(i18n.t("This field is required")),
	operationalSystem: yup.string().required(i18n.t("This field is required")),
	patrimonyTag: yup.string().required(i18n.t("This field is required")),
});

export default function DeviceAdd() {
	const queryClient = useQueryClient();
	const { data: deviceModelData, isLoading: isLoadingDeviceModels } =
		useQueryDeviceModelList(undefined);
	const { data: patrimonyTagData, isLoading: isLoadingPatrimonyTags } =
		useQueryPatrimonyTagList(undefined, true);
	const {
		data: operationalSystemData,
		isLoading: isLoadingOperationalSystems,
	} = useQueryOperationalSystemList(undefined);
	const { data: invoiceData, isLoading: isLoadingInvoices } =
		useQueryInvoiceList(undefined);

	function isLoadingDependency() {
		return (isLoadingDeviceModels &&
		isLoadingPatrimonyTags &&
		isLoadingOperationalSystems &&
		isLoadingInvoices)
	}

	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<DeviceForm>({
		resolver: yupResolver(DeviceAddFormSchema),
	});
	const onSubmit = handleSubmit(async (data: DeviceForm) => {
		setIsSending(true);

		await api.post("/devices", data).then(() => {
			queryClient.invalidateQueries(["device", "list"]);
			queryClient.invalidateQueries(["patrimony_tag", "list"]);
			navigate("/device");
		});

		setIsSending(false);
	});

	return (
		<>
			<PageTitle pretitle={t("Add")} title={t("Device")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoadingDependency() ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-12">
												<div className="col-xl-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Device Model")}
														</div>
														<select
															className={`form-select ${
																errors.deviceModel &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"deviceModel",
																{ value: "" }
															)}
														>
															<option value="" />

															{deviceModelData?.members.map(
																(
																	deviceModel
																) => {
																	return (
																		<option
																			key={
																				deviceModel.id
																			}
																			value={deviceModel["@id"]}
																		>{`${deviceModel.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.deviceModel && (
															<div className="invalid-feedback">
																{
																	errors
																		.deviceModel
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="col-xl-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Patrimony Tag")}
														</div>
														<select
															className={`form-select ${
																errors.patrimonyTag &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"patrimonyTag",
																{
																	value: "",
																}
															)}
														>
															<option value="" />
															{patrimonyTagData?.members.map(
																(
																	patrimonyTag
																) => {
																	return (
																		<option
																			value={patrimonyTag["@id"]}
																			key={
																				patrimonyTag.id
																			}
																		>{`${patrimonyTag.number}`}</option>
																	);
																}
															)}
														</select>
														{errors.patrimonyTag && (
															<div className="invalid-feedback">
																{
																	errors
																		.patrimonyTag
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="col-xl-6">
													<div className="mb-3">
														<div className="form-label">
															{t(
																"Operational System"
															)}
														</div>
														<select
															className={`form-select ${
																errors.operationalSystem &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"operationalSystem",
																{
																	value: "",
																}
															)}
														>
															<option value="" />
															{operationalSystemData?.members.map(
																(
																	operationalSystem
																) => {
																	return (
																		<option
																			value={operationalSystem["@id"]}
																			key={
																				operationalSystem.id
																			}
																		>{`${operationalSystem.name}`}</option>
																	);
																}
															)}
														</select>
														{errors.operationalSystem && (
															<div className="invalid-feedback">
																{
																	errors
																		.operationalSystem
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="col-xl-6">
													<div className="mb-3">
														<div className="form-label">
															{t("Invoice")}
														</div>
														<select
															className={`form-select ${
																errors.invoice &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"invoice",
																{
																	value: "",
																}
															)}
														>
															<option value="" />
															{invoiceData?.members.map(
																(invoice) => {
																	return (
																		<option
																			value={invoice["@id"]}
																			key={
																				invoice.id
																			}
																		>{`${invoice.number}`}</option>
																	);
																}
															)}
														</select>
														{errors.invoice && (
															<div className="invalid-feedback">
																{
																	errors
																		.invoice
																		.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Serial Number")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.serialNumber &&
															"is-invalid is-invalid-lite"
														}`}
														{...register(
															"serialNumber"
														)}
													/>
													{errors.serialNumber && (
														<div className="invalid-feedback">
															{
																errors
																	.serialNumber
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Description")}
													</label>
													<textarea
														className={`form-control ${
															errors.description &&
															"is-invalid is-invalid-lite"
														}`}
														data-bs-toggle="autosize"
														placeholder={t(
															"Type something…"
														)}
														{...register(
															"description"
														)}
													></textarea>

													{errors.description && (
														<div className="invalid-feedback">
															{
																errors
																	.description
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
