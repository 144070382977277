import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "../../services/i18n/i18n";
import { api } from "../../services/api";
import { LOW_PRIORITY } from "../../services/ConfigService";
import { PageTitle } from "../../components/Layout/PageTitle";
import { Eye, EyeOff } from "tabler-icons-react";

type UserForm = {
	name: string;
	email: string;
	plainPassword?: string;
	plainPasswordConfirmation?: string;
};

type UserType = {
	'@id': string;
	id: string;
	name: string;
	email: string;
	createdAt: Date;
	updatedAt: Date;
};

const UserFormSchema = yup.object().shape({
	name: yup.string().required(i18n.t("This field is required")),
	email: yup
		.string()
		.email(i18n.t("This field must be of type email"))
		.required(i18n.t("This field is required")),
	plainPassword: yup.string(),
	plainPasswordConfirmation: yup
		.string()
		.oneOf(
			[yup.ref("plainPassword"), null],
			i18n.t("Passwords must match")
		),
});

export default function UserEdit() {
	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const { id } = useParams();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data, isLoading } = useQuery<UserType>(
		[`user`, id],
		async () => {
			const response = await api.get(`/users/${id}`).then((response) => {
				return response;
			});

			return response.data as UserType;
		},
		{
			staleTime: Number(LOW_PRIORITY),
		}
	);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<UserForm>({
		resolver: yupResolver(UserFormSchema),
	});
	const onSubmit = handleSubmit(async (formData: UserForm) => {
		setIsSending(true);
		const userData: Omit<UserForm, "plainPasswordConfirmation"> = {
			email: formData.email,
			name: formData.name,
			plainPassword: formData.plainPassword,
		};
		data && api.put(data["@id"], userData).then(() => {
			queryClient.invalidateQueries(["user", "list"]);
			queryClient.invalidateQueries(["user", id]);
			setIsSending(false);
			navigate("/user");
		});
	});

	const handlePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	useEffect(() => {
		data &&
			reset({
				email: data.email,
				name: data.name,
			});
	}, [isLoading]);

	return (
		<>
			<PageTitle pretitle={t("Edit")} title={t("User")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoading ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12">
												<div className="mb-3">
													<label className="form-label">
														{t("Name")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.name &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("name")}
													/>
													{errors.name && (
														<div className="invalid-feedback">
															{
																errors.name
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3">
													<label className="form-label">
														{t("Email")}
													</label>
													<input
														type="email"
														className={`form-control ${
															errors.email &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("email")}
													/>
													{errors.email && (
														<div className="invalid-feedback">
															{
																errors.email
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3 col-6">
													<label className="form-label">
														{t("Password")}
													</label>
													<div className="d-flex flex-row align-items-center">
														<input
															type={
																passwordVisible
																	? "text"
																	: "password"
															}
															autoComplete="new-password"
															className={`form-control ${
																errors.plainPassword &&
																"is-invalid is-invalid-lite"
															}`}
															{...register(
																"plainPassword"
															)}
														/>
														{!passwordVisible ? (
															<Eye
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														) : (
															<EyeOff
																className="mx-3"
																cursor="pointer"
																onClick={() => {
																	handlePasswordVisibility();
																}}
															/>
														)}
													</div>
													{errors.plainPassword && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.plainPassword
																	.message
															}
														</div>
													)}
												</div>
												<div className="mb-3 col-6">
													<label className="form-label">
														{t(
															"Password confirmation"
														)}
													</label>
														<div className="d-flex flex-row align-items-center">
															<input
																type={
																	passwordVisible
																		? "text"
																		: "password"
																}
																autoComplete="new-password"
																className={`form-control ${
																	errors.plainPasswordConfirmation &&
																	"is-invalid is-invalid-lite"
																}`}
																{...register(
																	"plainPasswordConfirmation"
																)}
															/>
															{!passwordVisible ? (
																<Eye
																	className="mx-3"
																	cursor="pointer"
																	onClick={() => {
																		handlePasswordVisibility();
																	}}
																/>
															) : (
																<EyeOff
																	className="mx-3"
																	cursor="pointer"
																	onClick={() => {
																		handlePasswordVisibility();
																	}}
																/>
															)}
														</div>
													{errors.plainPasswordConfirmation && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.plainPasswordConfirmation
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
