import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
 } from 'react-router-dom';
import { useContext } from 'react';

import { AuthContext, AuthProvider } from './contexts/AuthContext';

import Layout from './components/Layout';

import Home from './pages/Home';
import NoPage from './pages/NoPage';
import Login from './pages/Login';

import { ThemeProvider } from './contexts/ThemeContext';

import ManufacturerList from './pages/Device/Manufacturer/ManufacturerList';
import ManufacturerAdd from './pages/Device/Manufacturer/ManufacturerAdd';
import ManufacturerEdit from './pages/Device/Manufacturer/ManufacturerEdit';
import PatrimonyTagList from './pages/Device/PatrimonyTag/PatrimonyTagList';
import PatrimonyTagAdd from './pages/Device/PatrimonyTag/PatrimonyTagAdd';
import PatrimonyTagEdit from './pages/Device/PatrimonyTag/PatrimonyTagEdit';
import OperationalSystemList from './pages/Device/OperationalSystem/OperationalSystemList';
import OperationalSystemAdd from './pages/Device/OperationalSystem/OperationalSystemAdd';
import OperationalSystemEdit from './pages/Device/OperationalSystem/OperationalSystemEdit';
import TypeEdit from './pages/Device/Type/TypeEdit';
import TypeAdd from './pages/Device/Type/TypeAdd';
import TypeList from './pages/Device/Type/TypeList';
import ModelList from './pages/Device/Model/ModelList';
import ModelAdd from './pages/Device/Model/ModelAdd';
import ModelEdit from './pages/Device/Model/ModelEdit';
import SupplierAdd from './pages/Supplier/Supplier/SupplierAdd';
import SupplierEdit from './pages/Supplier/Supplier/SupplierEdit';
import SupplierList from './pages/Supplier/Supplier/SupplierList';
import InvoiceAdd from './pages/Supplier/Invoice/InvoiceAdd';
import InvoiceEdit from './pages/Supplier/Invoice/InvoiceEdit';
import InvoiceList from './pages/Supplier/Invoice/InvoiceList';
import DeviceAdd from './pages/Device/Device/DeviceAdd';
import DeviceEdit from './pages/Device/Device/DeviceEdit';
import DeviceList from './pages/Device/Device/DeviceList';
import UserAdd from './pages/Users/UserAdd';
import UserEdit from './pages/Users/UserEdit';
import UserList from './pages/Users/UserList';

type PrivatePros = {
	children: JSX.Element
}

function Private({ children }: PrivatePros) {
	const { isAuthenticated, isLoading } = useContext(AuthContext);

	if (isLoading) {
		return (<div>CARREGANDO...</div>);
	}

	if (!isAuthenticated) {
		return <Navigate to={'/login'} />
	}

	return children;
}

export default function AppRoutes() {
	return(
		<Router>
			<AuthProvider>
				<ThemeProvider>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/' element={<Layout />}>
							<Route path='/' element={<Private><Home /></Private>} />
							<Route path='/device/manufacturer' element={<Private><ManufacturerList /></Private>} />
							<Route path='/device/manufacturer/add' element={<Private><ManufacturerAdd /></Private>} />
							<Route path='/device/manufacturer/edit/:id' element={<Private><ManufacturerEdit /></Private>} />
							<Route path='/device/patrimony-tag' element={<Private><PatrimonyTagList /></Private>} />
							<Route path='/device/patrimony-tag/add' element={<Private><PatrimonyTagAdd /></Private>} />
							<Route path='/device/patrimony-tag/edit/:id' element={<Private><PatrimonyTagEdit /></Private>} />
							<Route path='/device/operational-system' element={<Private><OperationalSystemList /></Private>} />
							<Route path='/device/operational-system/add' element={<Private><OperationalSystemAdd /></Private>} />
							<Route path='/device/operational-system/edit/:id' element={<Private><OperationalSystemEdit /></Private>} />
							<Route path='/device/device-type' element={<Private><TypeList /></Private>} />
							<Route path='/device/device-type/add' element={<Private><TypeAdd /></Private>} />
							<Route path='/device/device-type/edit/:id' element={<Private><TypeEdit /></Private>} />
							<Route path='/device/device-model' element={<Private><ModelList /></Private>} />
							<Route path='/device/device-model/add' element={<Private><ModelAdd /></Private>} />
							<Route path='/device/device-model/edit/:id' element={<Private><ModelEdit /></Private>} />
							<Route path='/device' element={<Private><DeviceList /></Private>} />
							<Route path='/device/add' element={<Private><DeviceAdd /></Private>} />
							<Route path='/device/edit/:id' element={<Private><DeviceEdit /></Private>} />
							<Route path='/supplier' element={<Private><SupplierList /></Private>} />
							<Route path='/supplier/add' element={<Private><SupplierAdd /></Private>} />
							<Route path='/supplier/edit/:id' element={<Private><SupplierEdit /></Private>} />
							<Route path='/supplier/invoice' element={<Private><InvoiceList /></Private>} />
							<Route path='/supplier/invoice/add' element={<Private><InvoiceAdd /></Private>} />
							<Route path='/supplier/invoice/edit/:id' element={<Private><InvoiceEdit /></Private>} />
							<Route path='/user' element={<Private><UserList /></Private>} />
							<Route path='/user/add' element={<Private><UserAdd /></Private>} />
							<Route path='/user/edit/:id' element={<Private><UserEdit /></Private>} />
						</Route>
						<Route path="*" element={<NoPage />} />
					</Routes>
				</ThemeProvider>
			</AuthProvider>
		</Router>
	);
}
