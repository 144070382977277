import { useContext } from "react";

import { AuthContext } from "../../contexts/AuthContext";

import LogoFarolSign from "../../assets/images/icone_logo_farolsign.svg";
import Avatar from "react-avatar";

import { Sun as IconTablerSun } from 'tabler-icons-react';
import { Moon as IconTablerMoon } from 'tabler-icons-react';
import { ThemeContext } from "../../contexts/ThemeContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Header() {
	const { user, signOut } = useContext(AuthContext);
	const { theme, toggleTheme } = useContext(ThemeContext);

	const { t } = useTranslation();

	return (
		<header className="navbar navbar-expand-md navbar-light d-print-none">
			<div className="container-xl">
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
					<span className="navbar-toggler-icon" />
				</button>
				<h1 className="navbar-brand d-none-navbar-horizontal pe-0 pe-md-3">
					<Link
						to={'/'}
					>
						<img src={LogoFarolSign} height={60} alt="Farol Sign" className="navbar-brand-image" />
					</Link>
				</h1>
				<div className="navbar-nav flex-row order-md-last">
					<div className="d-none d-md-flex">
						<a
							href="#"
							className="nav-link px-0"
							title={t("Enable dark mode")}
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							onClick={(e) => {
								e.preventDefault();
								toggleTheme();
							}}
						>
							{theme === 'light' ? (
								<IconTablerMoon />
							) : (
								<IconTablerSun />
							)}
						</a>
					</div>
					<div className="nav-item dropdown">
						<a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
							<Avatar
								name={user?.name}
								className={'avatar avatar-sm'}
								color={'#626976'}
								size={'32'}
							/>
							<div className="d-none d-xl-block ps-2">
								<div>{user?.name}</div>
								<div className="mt-1 small text-muted">{user?.username}</div>
							</div>
						</a>
						<div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
							<a href="/profile" className="dropdown-item">{t('Profile')}</a>
							<div className="dropdown-divider" />
							<a
								href="#"
								className="dropdown-item"
								onClick={(e) => {
									e.preventDefault();
									signOut();
								}}
							>
								{t('Logout')}
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
