import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../components/Layout/PageTitle";
import { api } from "../../../services/api";
import { LOW_PRIORITY } from "../../../services/ConfigService";

import i18n from "../../../services/i18n/i18n";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type PatrimonyTagForm = {
	number: string;
};

type PatrimonyTagType = {
	'@id': string;
	id: string;
	number: string;
	createdAt: Date;
	updatedAt: Date;
};

const PatrimonyTagFormSchema = yup.object().shape({
	number: yup.string().required(i18n.t("This field is required")),
});

export default function PatrimonyTagEdit() {
	const navigate = useNavigate();
	const [isSending, setIsSending] = useState(false);
	const { id } = useParams();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data, isLoading } = useQuery<PatrimonyTagType>(
		[`patrimony_tag`, id],
		async () => {
			const response = await api
				.get(`/patrimony_tags/${id}`)
				.then((response) => {
					return response;
				});

			return response.data as PatrimonyTagType;
		},
		{
			staleTime: Number(LOW_PRIORITY),
		}
	);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<PatrimonyTagForm>({
		resolver: yupResolver(PatrimonyTagFormSchema),
	});
	const onSubmit = handleSubmit(async (formData: PatrimonyTagForm) => {
		setIsSending(true);
		data && api.put(data["@id"], formData).then(() => {
			queryClient.invalidateQueries(["patrimony_tag", "list"]);
			queryClient.invalidateQueries(["patrimony_tag", id]);
			setIsSending(false);
			navigate("/device/patrimony-tag");
		});
	});

	useEffect(() => {
		data && reset({ number: data.number });
	}, [isLoading]);

	return (
		<>
			<PageTitle pretitle={t("Edit")} title={t("Patrimony Tag")} />
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							{isLoading ? (
								<div className="d-flex justify-content-center p-3">
									<h2>
										{t("Loading")}
										<span className="animated-dots"></span>
									</h2>
								</div>
							) : (
								<form className="card" onSubmit={onSubmit}>
									<div className="card-header">
										<h4 className="card-title">
											{t("Form")}
										</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12">
												<div className="mb-3">
													<label className="form-label">
														{t("Number")}
													</label>
													<input
														type="text"
														className={`form-control ${
															errors.number &&
															"is-invalid is-invalid-lite"
														}`}
														{...register("number")}
													/>
													{errors.number && (
														<div className="invalid-feedback">
															{
																errors.number
																	.message
															}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<div className="d-flex">
											<button
												type="submit"
												className={`btn btn-primary ms-auto ${
													isSending &&
													"disabled btn-loading"
												}`}
											>
												{t("SAVE")}
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
