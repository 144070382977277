import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import ENUS from "./locales/en-US/en-US.json";
import PTBR from "./locales/pt-BR/pt-BR.json";

const resources = {
	"pt-BR": PTBR,
	"en": ENUS,
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en-US",
		debug: false,
		detection: {
			order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
